<template>
  <div id="app" class="app">
    <div class="homeM" :class="test || showPolicy ? 'home_active' : ''">
      <header class="header">
        <div @click="showPolicyBtn" class="btn">{{ $t("mobile.title") }}</div>
      </header>
      <!-- 主体 -->
      <div class="title">
        <!-- 标题 -->
        <div class="title_content">
          <img
            class="logo_img logo_img_active"
            src="/img/new_play02.png"
            alt=""
          />
          <div class="content_1">
            <img class="img01" src="/img/new_play03.png" alt="" />
          </div>
          <div class="content_2">
            <img
              @click="downloadAppBtn"
              class="img"
              src="/img/new_play04.png"
            />
            <img
              @click="downloadAppBtn"
              class="img"
              src="/img/new_play05.png"
            />
            <img
              @click="downloadAppBtn"
              class="img"
              src="/img/new_play06.png"
            />
            <img class="img02" src="/img/new_play07.png" />
          </div>
          <!-- 视频 -->
          <div class="content_3">
            <video
              ref="video"
              webkit-playsinline="true"
              playsinline="true"
              x-webkit-airplay="allow"
              x5-video-player-type="h5"
              x5-video-player-fullscreen="true"
              x5-video-orientation="portraint"
              class="video_url"
              controls
              src="/img/video.m4v"
              poster="/img/new_play13.png"
            ></video>
          </div>
          <div class="content_4">{{ $t("mobile.name") }}</div>
          <!-- 轮播图 -->
          <div class="content_5">
            <!-- 轮播图 -->
            <swiper class="swiper" :options="swiperOption" ref="mySwiper">
              <swiperSlide
                class="swiper-slide"
                v-for="(item, index) in swiperList"
                :key="index"
              >
                <div class="swiper_item">
                  <img class="img" :src="item.img" alt="" />
                </div>
              </swiperSlide>
            </swiper>
            <div class="swiper-text">
              <div class="top">
                <div
                  class="item"
                  :class="index == oIndex ? 'active' : ''"
                  v-for="(item, index) in swiperList.length"
                  :key="index"
                ></div>
              </div>
            </div>
          </div>
          <!-- 说明 -->
          <div class="content_6">
            <span class="title_info">{{ $t("mobile.articleTitle") }}</span>
            {{ $t("mobile.articleText") }}
          </div>
        </div>
      </div>
    </div>
    <div class="mobile-footer">
      <div class="left">{{ $t("mobile.downText") }}</div>
      <div class="right">
        <img @click="downloadAppBtn" class="img" src="/img/new_play06.png" />
      </div>
    </div>
    <!-- 隐私政策弹框 -->
    <div
      class="mask policy_mask"
      v-if="showPolicy"
      @click.prevent="cancelPolicy"
    >
      <div class="policy_content" @click.stop>
        <div class="policy_top">
          <span class="policy_top_title">Privacy Policy</span>
        </div>
        <div class="policy_main">
          <span class="text">
            You acknowledge that you have read and understood this privacy
            policy. If you do not agree with this privacy policy, you must not
            use our Services. If you change your mind in the future, you may
            withdraw your consent to use of your personal information in
            accordance with this policy.
          </span>
          <span class="text">
            We know your privacy is very important to you, so please take the
            time to carefully read our privacy policy. Our policy is designed to
            help you understand:
          </span>
          <span class="text">
            · What information do we collect about you;
          </span>
          <span class="text">
            · How will we use the information about you;
          </span>
          <span class="text"> · Who do we share your information with; </span>
          <span class="text">
            · How can I exercise my rights over my information;
          </span>
          <span class="text"> · Dispute Resolution; </span>
          <span class="text"> · How will we notify you of changes; </span>
          <span class="text"> · Contact Information; </span>
          <span class="text">
            This privacy policy applies to all our services that directly
            reference or link to this policy, but does not apply to services
            that have separate privacy policies that do not incorporate this
            policy.
          </span>
          <span class="text"> SECTION 1: WHERE WE ARE A DATA CONTROLLER </span>
          <span class="text"> 1.The Types of Personal Information We Use </span>
          <span class="text">
            This section describes the different types of personal information
            we collect and how we collect it. We will collect and use the
            following information about you:
          </span>
          <span class="text"> Information you provide us. </span>
          <span class="text"
            >· when you register for the Game in order to set up your profile,
            including your name as it appears on your profile picture;</span
          >
          <span class="text"
            >· when you log-in as a 'guest' in order to use the Game (your
            nickname);</span
          >
          <span class="text"
            >· when you participate in a survey in the Game (in which case we
            will process the information you provide to us as part of the
            survey).</span
          >
          <span class="text"
            >· information you provide when seeking help from us such as your
            name, telephone number and records of the issues you
            experience;</span
          >
          <span class="text"
            >· Billing information, such as your name, payment card number and
            payment account details;</span
          >
          <span class="text">Information we collect about you.</span>
          <span class="text"
            >We automatically collect certain data from you when you use the
            Game, including your IP address and device information (such as
            application version, network type, OS) and information regarding
            your use of the Game, such as date and time stamps of actions. We
            also collect and store your data each time you interact with us, for
            example, when you use in-app support to communicate with us.</span
          >
          <span class="text"
            >Information you generate and display in the course of using the
            Game Services.</span
          >
          <span class="text"
            >You may provide additional information as part of your profile
            (such as gender). You may also provide or generate information when
            you communicate with other users in the Game (e.g., chat log data,
            chat content).</span
          >
          <span class="text">Data we collect from our partners.</span>
          <span class="text"
            >· Demographic data (such as to determine the coarse location of
            your IP address)</span
          >
          <span class="text"
            >· Data to fight fraud (such as refund abuse in games or click fraud
            in advertising)</span
          >
          <span class="text"
            >· Data from platforms that the games run on (such as to verify
            payment)</span
          >
          <span class="text"
            >· Data for advertising and analytics purposes, so we can provide
            you a better Service</span
          >
          <span class="text">Cookies.</span>
          <span class="text"
            >We use cookies and other similar technologies (e.g. web beacons,
            log files, scripts and eTags) ("Cookies") to enhance your experience
            using the Services. Cookies are small files which, when placed on
            your device, enable us to provide certain features and
            functionality. You have the option to permit installation of such
            Cookies or subsequently disable them. You may accept all cookies, or
            instruct the device (in the case of the Game) to provide notice at
            the time of installation of cookies, or refuse to accept all cookies
            by adjusting the relevant cookie retention function in your device.
            However, in the event of your refusal to install cookies, the Game
            may be unable to operate as designed.</span
          >
          <span class="text">2.How we use your personal information</span>
          <span class="text"
            >We will use the information in the following ways:</span
          >
          <img class="img" src="/img/new_mobile10.jpg" alt="" />
          <span class="text">analyze, profile, and segment.</span>
          <span class="text"
            >In all of the above cases and purposes, we may analyze, profile and
            segment all collected data.</span
          >
          <span class="text">Legal Basis for Processing</span>
          <span class="text"
            >If you are a resident of the European Union, we rely on a number of
            legal grounds to process information about you. For example, we will
            process information about you where we have your consent, where we
            have a legitimate interest to do so, where the processing is
            necessary for the performance of a contract with you, and where we
            have a legal obligation to process your information. For example, we
            rely on our legitimate interests to serve targeted marketing and on
            contractual necessity to process information when you create an
            account. To learn more about the legal grounds on which we rely to
            process your information for any particular purpose, please contact
            us via the options in Contact & Complaints below.</span
          >
          <span class="text">3.How We Share Your Personal Information</span>
          <span class="text"
            >We share your personal information with selected third parties in
            and outside your country, including:</span
          >
          <span class="text"
            >· other players and users. Social features are a core component of
            our games. Other players and users may, for example, see your
            profile data, in-game activities and read the messages you have
            posted.</span
          >
          <span class="text"
            >· partners working for our company has partners to perform services
            for us. These partners process your data only at and according to
            our instructions to provide the Service, such as hosting, player
            support, advertising, analytics and fraud prevention.</span
          >
          <span class="text"
            >· other companies and public authorities.In order to combat fraud
            and illegal activity, we may exchange data with other companies and
            organizations and provide it to public authorities in response to
            lawful requests. We may also disclose your data based on your
            consent, to comply with the law or to protect the rights, property
            or safety of us, our players or others.</span
          >
          <span class="text"
            >· related group companies,with whom we share your personal
            information to operate the Game Services;</span
          >
          <span class="text"
            >· law enforcement agencies, public authorities or other judicial
            bodies and organization.</span
          >
          <span class="text"
            >· a third party that acquires all or substantially all of us or our
            business.We will disclose information to a third party in the event
            that we sell or buy any business or undergo a merger, in which case
            we will disclose your data to the prospective buyer of such
            business. We will also disclose information to a third party if we
            sell, buy, merge or partner with other companies or businesses, or
            sell some or all of our assets. In such transactions, user
            information may be among the transferred assets.</span
          >
          <span class="text">4.Data Retention</span>
          <span class="text"
            >We will retain your personal information as described below (except
            if otherwise required to be retained by applicable law).</span
          >
          <img class="img" src="/img/new_mobile11.jpg" alt="" />
          <span class="text"
            >Personal Information is kept until the end of its life cycle (as
            set out in the retention policy above). When destroying personal
            information, measures will be taken to make the personal information
            irrecoverable or irreproducible, and electronic files which contain
            personal information will be deleted permanently using a technical
            method which makes the files irreproducible.</span
          >
          <span class="text"
            >In the event that the processing and retention period have
            terminated, but personal information is required to be retained
            continuously for other reasons including for the purposes as
            prescribed under applicable laws, the relevant personal information
            will be stored and maintained separately from other types of
            personal information. If you require us to destroy your personal
            information before the end of its life cycle (as set out in the
            retention policy above), we will destroy your personal information
            in accordance with local laws.</span
          >
          <span class="text">SECTION 2: MISCELLANEOUS</span>
          <span class="text">1.Information security</span>
          <span class="text"
            >We have implemented administrative, physical and technical security
            measures that are designed to protect your information from loss,
            theft, misuse, unauthorized access, disclosure, alteration and
            destruction. You should understand though that, despite our efforts,
            no security can be guaranteed as impenetrable.</span
          >
          <span class="text">2.Information about children</span>
          <span class="text"
            >We do not knowingly collect or solicit personal information from or
            direct or target interest based advertising to anyone under the age
            of 16 or knowingly allow such persons to use our Services. If you
            are under 16, please do not send any information about yourself to
            us, including your name, address, telephone number, or email
            address. No one under the age of 16 may provide any personal
            information. In the event that we learn that we have collected
            personal information from a child under age 16, we will delete that
            information as quickly as possible. If you believe that we might
            have any information from or about a child under the age of 16,
            please contact us.</span
          >
          <span class="text"
            >3.Links to Sites and Service Operated by Others</span
          >
          <span class="text"
            >Our Service may contain links to other sites, applications, and
            services. Because we does not operate those other sites,
            applications, and services, we cannot take responsibility for the
            privacy practices of the entities that operate them. We recommend
            that you consistently check privacy policies to understand an
            operator's practices. As a reminder, this policy describes our
            practices.</span
          >
          <span class="text">4.Changes</span>
          <span class="text"
            >We may update this privacy policy from time to time. When we update
            it, we will revise the "Last Updated" date above. If we make
            material changes in the way we collect, use, retain or share your
            personal information, we will notify you by sending you an email at
            the last email address that you provided us, or by posting notice of
            the changes on the services covered by this privacy policy.</span
          >
          <span class="text">5.International Transfer</span>
          <span class="text"
            >We may transfer information that we collect about you to affiliated
            entities, or to other third parties across borders and from your
            country or jurisdiction to other countries or jurisdictions around
            the world. Please note that these countries and jurisdictions may
            not have the same data protection laws as your own jurisdiction, and
            we take steps to ensure adequate safeguards are in place to enable
            transfer of information and the use and disclosure of information
            about you, including personal information, as described in this
            Policy.</span
          >
          <span class="text">6.Contact & Complaints</span>
          <span class="text"
            >Questions, comments and requests regarding this policy are
            welcomed. Please contact us via email at matasejarah@gmail.com. In
            the event that you wish to make a complaint about how we process
            your personal information, please contact us in the first instance
            at matasejarah@gmail.com and we will endeavor to deal with your
            request as soon as possible. This is without prejudice to your right
            to launch a claim with the data protection authority in the country
            in which you live or work where you think we have infringed data
            protection laws.</span
          >
        </div>
        <div class="foot_btn" @click="closePolicyBtn">ok</div>
      </div>
    </div>
    <!-- 微信浏览器提示 -->
    <div class="mask we_cheat" v-if="showWeCheat" @click="cancelWeCheatBtn">
      <img class="mask_img" src="/img/new_mobile08.png" alt="" />
    </div>
  </div>
</template>
<script>
import { channelInfo, configInfo } from "@/api/customer";
import { BASE64 } from "../utils/base64.js";
import { downloadUrl } from "@/api/customer";
import { appDownload } from "@/api/download";
import copy2clipboard from "@/utils/copy2clipboard";
import urlUtils from "@/utils/urlUtils";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
const c_swiper = document.getElementsByClassName("swiper");
import axios from "axios";

import vueSeamlessScroll from "vue-seamless-scroll";
export default {
  name: "Mobile",
  components: {
    swiper,
    swiperSlide,
    vueSeamlessScroll,
  },
  data() {
    return {
      test: false,
      //微信浏览器
      showWeCheat: false,
      userMobile: "",
      //进度条
      downProgress: 0,
      //定时器
      downloadInterval: null,
      //隐私政策
      showPolicy: false,
      //轮播图
      //选中轮播图
      oIndex: 0,
      swiperOption: {
        // 是否循环
        loop: false,
        // 默认显示几张图片
        slidesPerView: 1,
        // 显示分页
        pagination: {
          el: ".swiper-pagination",
          clickable: true, //允许分页点击跳转
        },
        autoplay: {
          delay: 5000, //自动切换的时间间隔，单位ms
          stopOnLastSlide: false, //当切换到最后一个slide时停止自动切换
          disableOnInteraction: false, //用户操作swiper之后，是否禁止autoplay
        },
        // 设置点击箭头
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        on: {
          // 监听slide切换结束后
          //  slideChange:() => {console.log("轮播图滚动事件 --->",c_swiper[0].swiper.activeIndex)}
          slideChange: () => {
            this.oIndex = c_swiper[0].swiper.activeIndex;
          },
        },
      },
    };
  },
  computed: {
    swiperList() {
      return [
        {
          id: 1,
          img: this.$t("mobile.img01"),
        },
        {
          id: 2,
          img: this.$t("mobile.img02"),
        },
        {
          id: 3,
          img: this.$t("mobile.img03"),
        },
        {
          id: 4,
          img: this.$t("mobile.img04"),
        },
        {
          id: 5,
          img: this.$t("mobile.img05"),
        },
      ];
    },
  },
  beforeDestroy() {
    // 组件销毁前清除定时器
    if (this.downloadInterval) {
      clearInterval(this.downloadInterval);
    }
  },
  methods: {
    switchImg(index) {
      //参数index为图片索引
      this.oIndex = index;
      c_swiper[0].swiper.activeIndex = index;
    },
    //展示隐私政策
    showPolicyBtn() {
      this.showPolicy = true;
      this.$refs.video.style.display = "none";
    },
    closePolicyBtn() {
      this.showPolicy = false;
      this.$refs.video.style.display = "block";
    },
    //关闭隐私政策
    cancelPolicy() {
      this.showPolicy = false;
    },
    //下载app
    downloadAppBtn() {
      // 版本1快手
      // kwaiq.track("buttonClick");
      //点击事件
      ttq.track("ClickButton", {
        contents: [
          {
            content_id: "4",
            content_type: "ClickButton",
            content_name:
              "lqhd_hunterblade_tiancan_4_2.4.0_202408161335_android.apk",
          },
        ],
      });
      // 判断是否是微信浏览器
      var is_wx = this.is_weixin();
      if (is_wx) {
        this.showWeCheat = true; // 是微信浏览器，则“点击右上角选择在浏览器中打开”文字显示
      } else {
        this.showWeCheat = false; // 不是微信浏览器，则“点击右上角选择在浏览器中打开”文字不显示
        var u = navigator.userAgent;
        var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
        var iPhone = u.indexOf("iPhone") > -1 || u.indexOf("Mac") > -1;
        var iPad = u.indexOf("iPad") > -1;
        if (isiOS || iPhone || iPad) {
          this.userMobile = "ios";
        } else {
          this.userMobile = "android";
        }
        this.downloadApi();
        this.test = false;
        if (this.userMobile == "ios") {
        } else if (this.userMobile == "android") {
        }
      }
    },
    downloadApi() {
      let _this = this;
      //版本1
      const url =
        "https://dl.hldl663.com/lqhd_hunterblade_tiancan_1_2.4.0_202408151413_android.apk";
      //版本2
      // const url =
      //   "https://dl.gamefzjw.com/lqhd_hunterblade_tiancan_2_2.4.0_202408161054_android.apk";
      //版本3
      // const url =
      //   "https://dl.glgdtt.com/lqhd_hunterblade_tiancan_3_2.4.0_202408161102_android.apk";
      // 版本4
      // const url =
      //   "https://app.adjust.com/1fu821m8?campaign={{campaign.name}}%20%28{{campaign.id}}%29&adgroup={{adset.name}}%20%28{{adset.id}}%29&creative={{ad.name}}%20%28{{ad.id}}%29&redirect=https%3A%2F%2Fdl.cxcygame.com%2Flqhd_hunterblade_tiancan_4_2.4.0_202408161335_android.apk";

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "HunterBlade.apk");
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
      //下载事件
      ttq.track("Download", {
        contents: [
          {
            content_id: "4",
            content_type: "Download",
            content_name:
              "lqhd_hunterblade_tiancan_4_2.4.0_202408161335_android.apk",
          },
        ],
      });
      // 版本1快手
      // kwaiq.track("download");
    },
    //展示图片详情
    showImgBtn(id) {
      this.$router.push({
        name: "ImgInfo",
        params: {
          id,
        },
      });
    },
    //展示下载按钮弹框
    showMaskBtn() {
      this.test = true;
    },
    //去掉下载按钮弹框
    cancelMask() {
      this.test = false;
    },
    // 判断是否是微信浏览器
    is_weixin() {
      var ua = window.navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return true;
      } else {
        return false;
      }
    },
    //关闭微信蒙层
    cancelWeCheatBtn() {
      this.showWeCheat = false;
    },
  },
};
</script>

<style lang="less" scoped>
.app {
  width: 100vw;
  height: 100vh;
  overflow: auto;
}
.homeM {
  width: 100vw;
  height: 100%;
  background: #fff;
  //顶部
  .header {
    width: 100%;
    height: 1.35rem;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 0.6rem;
    .btn {
      background: #8b25a1;
      border-radius: 0.1rem;
      padding: 0.25rem 0.2rem;
      font-family: OPPOSansR;
      font-size: 0.32rem;
      color: #ffffff;
    }
  }
  //主体内容
  .title {
    width: 100%;
    height: auto;
    background: #fff;
    background-size: cover;
    padding: 0 0 0.375rem;
    //标题
    .title_content {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      background: url(/img/new_play01.png) no-repeat;
      background-size: contain;
      padding: 0 0.4rem;
      position: relative;
      .logo_img {
        position: absolute;
        top: -0.9rem;
        width: 2.5rem;
        height: 2.5rem;
        object-fit: cover;
      }
      .content_1 {
        margin-top: 0.4rem;
        padding-right: 0.8rem;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .img01 {
          width: 2.2rem;
          height: auto;
          object-fit: contain;
          position: relative;
          top: 0.3rem;
        }
      }
      .content_2 {
        margin-top: 0.3rem;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        .img {
          width: 2.5rem;
          height: auto;
          object-fit: contain;
          margin-right: 0.3rem;
          animation: pulse 2s 1s infinite;
          &:last-child {
            margin-right: 0;
          }
        }
        .img02 {
          position: absolute;
          top: -0.36rem;
          left: 2.4rem;
          width: 0.65rem;
          height: 0.65rem;
          object-fit: cover;
          animation: pulse 2s 1s infinite;
        }
      }
      // 视频
      .content_3 {
        margin-top: 0.2rem;
        width: 100%;
        background: #fff;
        padding: 0.3rem;
        border-radius: 0.6rem;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 0 0.4rem 0 #000000;
        .video_url {
          width: 100%;
          height: auto;
          min-height: 5rem;
          object-fit: cover;
          border-radius: 0.3rem;
          display: block;
        }
        /* 确保视频元素不会遮住其他元素 */
        video {
          display: none;
        }
        /* 当视频开始播放时显示视频，并将其置于底层 */
        video.playing {
          display: block;
          z-index: 0;
        }
      }
      .content_4 {
        width: 100%;
        margin-top: 0.6rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: OPPOSansM;
        font-size: 0.5rem;
        font-weight: bold;
        color: #8b25a1;
      }
      .content_5 {
        margin-top: 0.6rem;
        width: 100%;
        position: relative;
      }
      // 说明
      .content_6 {
        margin-top: 0.8rem;
        padding-bottom: 2rem;
        width: 100%;
        display: inline-block;
        font-family: OPPOSansR;
        font-size: 0.35rem;
        color: #25002f;
        .title_info {
          font-family: OPPOSansM;
          font-size: 0.5rem;
          font-weight: bold;
          color: #8b25a1;
          display: inline-block;
        }
      }
    }
    //轮播图
    .swiper {
      width: 100%;
      position: relative;
      .swiper_item {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        .img {
          width: 100%;
          height: auto;
          object-fit: contain;
          display: block;
          border-radius: 0.3rem;
        }
      }
    }
    //切换轮播图
    .swiper-text {
      width: 100%;
      padding-top: 0.5rem;
      .top {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .item {
          width: 0.4rem;
          height: 0.4rem;
          background: #d8d8d8;
          border-radius: 50%;
          margin-right: 0.1rem;
          &:last-child {
            margin-right: 0;
          }
        }
        .active {
          width: 0.5rem;
          height: 0.5rem;
          background: #f9cb29;
        }
      }
    }
  }
}
.mobile-footer {
  width: 100%;
  overflow: auto;
  position: fixed;
  bottom: 0;
  background: #a350b3;
  opacity: 1;
  z-index: 9999;
  padding: 0.2rem 0.41rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .left {
    font-family: OPPOSansM;
    font-size: 0.4rem;
    color: #ffffff;
  }
  .right {
    .img {
      width: 2.8rem;
      height: auto;
      object-fit: contain;
    }
  }
}
.home_active {
  overflow: hidden;
}
.mask {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background: rgba(0, 0, 0, 0.6);
  z-index: 99999;
  .mask_img {
    width: 3.95rem;
    height: 2.6rem;
    object-fit: cover;
  }
  .mask_content {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: auto;
    background: #ffffff;
    border-radius: 0.3rem 0.3rem 0 0;
    .mask_top {
      width: 100%;
      padding: 0.2rem 0.325rem 0.25rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid #e5e5e5;

      .mask_top_line {
        width: 1.175rem;
        height: 0.0875rem;
        background: rgba(160, 160, 160, 0.3);
        border-radius: 0.05rem;
      }
      .mask_top_logo {
        margin-top: 0.5rem;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          .logo_img {
            flex: none;
            width: 1.1125rem;
            height: 1.1125rem;
            object-fit: cover;
            margin-right: 0.4rem;
          }
          .logo_text {
            height: 1.1125rem;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            .logo_text_name {
              font-family: OPPOSansM;
              font-weight: 400;
              font-size: 0.3875rem;
              color: #151515;
            }
            .logo_text_app {
              margin-top: 0.1rem;
              font-family: OPPOSansM;
              font-weight: 400;
              font-size: 0.25rem;
              color: #151515;
            }
          }
        }
        .right {
          min-width: 2.0375rem;
          height: 0.8rem;
          padding: 0 0.2rem;
          background: #035d8d;
          border-radius: 32px 32px 32px 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: OPPOSansM;
          font-weight: 300;
          font-size: 0.3rem;
          color: #ffffff;
        }
      }
    }
    .mask_img_content {
      width: 100%;
      overflow-x: scroll;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 0.625rem 0.625rem 0.5rem;
      border-radius: 0.3rem;
      &::-webkit-scrollbar {
        /*滚动条整体样式*/
        width: 6px; /*高宽分别对应横竖滚动条的尺寸*/
        height: 1px;
        opacity: 0;
      }
      &::-webkit-scrollbar-thumb {
        /*滚动条里面小方块*/
        border-radius: 5px;
        background: #fff;
        opacity: 0;
      }
      &::-webkit-scrollbar-track {
        /*滚动条里面轨道*/
        border-radius: 6px;
        background: #fff;
        opacity: 0;
      }
      .mask_img_item {
        flex: none;
        margin-right: 0.275rem;
        &:last-child {
          margin-right: 0;
        }
        .mask_img {
          width: 4.525rem;
          height: auto;
          display: inline-block;
          object-fit: contain;
          border-radius: 0.3rem;
        }
      }
    }
  }
}
.we_cheat {
  text-align: right;
}

//隐私政策弹框
.policy_mask {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  .policy_content {
    width: 100%;
    height: auto;
    background: #ffffff;
    border-radius: 0.3rem;
    .policy_top {
      width: 100%;
      padding: 0.55rem 0.4rem 0.25rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-family: OPPOSansM;
      font-weight: bold;
      font-size: 0.45rem;
      color: #151515;
    }
    .policy_main {
      width: 100%;
      overflow-y: scroll;
      height: 10rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 0 0.4rem 0.25rem;
      font-family: OPPOSansM;
      font-size: 0.3rem;
      color: #151515;
      line-height: 0.45rem;
      &::-webkit-scrollbar {
        width: 6px;
      }
      &::-webkit-scrollbar-track {
        background-color: rgba(0, 0, 0, 0);
      }
      &::-webkit-scrollbar-thumb {
        width: 8px;
        height: 48px;
        background: #d9d9d9;
        border-radius: 12px;
      }
      .text {
        text-indent: 2em;
      }
      .img {
        width: 100%;
        height: auto;
        object-fit: contain;
        margin: 0.1rem 0;
      }
    }
    .foot_btn {
      width: 100%;
      height: 48px;
      border-top: 1px solid #e5e5e5;
      display: flex;
      align-items: center;
      justify-content: space-between;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: OPPOSansM;
      font-size: 0.5rem;
      color: #6f6f6f;
    }
  }
}

//动画
@keyframes pulse {
  0% {
    transform: scaleX(1);
  }
  50% {
    transform: scale3d(1.02, 1.02, 1.02);
  }
  100% {
    transform: scaleX(1);
  }
}
</style>
