<template>
  <div class="app">
    <div class="mask" @click.prevent="cancelMask">
      <img class="icon" src="/img/new_mobile07.png" alt="" />
      <div class="mask_content" @click.stop>
        <!-- 轮播图 -->
        <div class="swiper_content">
          <swiper class="swiper" :options="swiperOption" ref="mySwiper">
            <swiperSlide
              class="swiper_slide"
              v-for="(item, index) in imageData"
              :key="index"
            >
              <div class="swiper_item">
                <img class="img" :src="item.url" alt="" />
              </div>
            </swiperSlide>
          </swiper>
          <div class="swiper_dot">
            <div class="top">
              <div
                class="item"
                :class="index == oIndex ? 'active' : ''"
                v-for="(item, index) in imageData.length"
                :key="index"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { channelInfo, configInfo } from "@/api/customer";
import { BASE64 } from "../utils/base64.js";
import { downloadUrl } from "@/api/customer";
import { appDownload } from "@/api/download";
import copy2clipboard from "@/utils/copy2clipboard";
import urlUtils from "@/utils/urlUtils";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
const c_swiper = document.getElementsByClassName("swiper");

import vueSeamlessScroll from "vue-seamless-scroll";
export default {
  name: "Mobile",
  components: {
    swiper,
    swiperSlide,
    vueSeamlessScroll,
  },
  data() {
    return {
      test: false,
      //选中轮播图
      oIndex: 0,
      swiperOption: {
        // 是否循环
        loop: false,
        // 默认显示几张图片
        slidesPerView: 1,
        // 显示分页
        pagination: {
          el: ".swiper-pagination",
          clickable: true, //允许分页点击跳转
        },
        // 设置点击箭头
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        on: {
          // 监听slide切换结束后
          //  slideChange:() => {console.log("轮播图滚动事件 --->",c_swiper[0].swiper.activeIndex)}
          slideChange: () => {
            this.oIndex = c_swiper[0].swiper.activeIndex;
          },
        },
      },
    };
  },
  computed: {
    imageData() {
      return [
        {
          id: 1,
          url: this.$t("mobile.imgUrl01"),
        },
        {
          id: 2,
          url: this.$t("mobile.imgUrl02"),
        },
        {
          id: 3,
          url: this.$t("mobile.imgUrl03"),
        },
      ];
    },
  },
  mounted() {
    if (this.$route.params.id) {
      this.oIndex = this.$route.params.id - 1;
      c_swiper[0].swiper.activeIndex = this.oIndex;
    }
  },
  beforeRouteLeave(to, from, next) {
    // 销毁当前页面的实例
    this.$destroy();
    next(); // 继续路由跳转
  },
  methods: {
    //展示图片详情
    showImgBtn(id) {
      console.log(id);
    },
    //展示弹框
    showMaskBtn() {
      this.test = true;
    },
    switchImg(index) {
      //参数index为图片索引
      this.oIndex = index;
      c_swiper[0].swiper.activeIndex = index;
    },
    //去掉蒙层
    cancelMask() {
      this.$router.go(-1); // 类似history.forward()
    },
    // 判断是否是微信浏览器
    is_weixin() {
      var ua = window.navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.app {
  width: 100vw;
  height: 100vh;
  overflow: auto;
}
.mask {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background: rgba(0, 0, 0, 1);
  z-index: 99999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .icon {
    position: absolute;
    top: 0.6rem;
    left: 0.6rem;
    width: 0.6rem;
    height: 0.6rem;
    object-fit: cover;
  }
  .mask_content {
    width: 100%;
    height: auto;
  }
}
//切换轮播图
.swiper {
  width: 100%;
  height: auto;
  ::v-deep .swiper-wrapper {
    .swiper_slide {
      .swiper_item {
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        .img {
          display: inline-block;
          width: 80%;
          height: auto;
          // border-radius: 0.6rem;
          object-fit: contain;
        }
      }
    }
  }
}
.swiper_dot {
  position: fixed;
  left: 50%;
  bottom: 0.6rem;
  transform: translate(-50%);
  .top {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .item {
      width: 0.2rem;
      height: 0.2rem;
      background: #616161;
      border-radius: 50%;
      margin-right: 0.2rem;
      &:last-child {
        margin-right: 0;
      }
    }
    .active {
      background: #ffffff;
    }
  }
}
</style>
