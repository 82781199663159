export default {
    mobile: {
        title: 'pernyataan privasi',
        name: 'Fitur Game',
        articleTitle: 'Warisan Tari Pedang',
        articleText: 'Warisan Tari Pedang adalah MMORPG bertema peri oriental. Game ini mengadopsi penelitian dan pengembangan teknologi mesin Unity 3D, perpaduan elemen batu dan elemen peri, konsep “peri batu” pertama. Gim ini kaya akan gameplay, dengan gameplay PVT dan PVE seperti pertempuran kamp, liga abadi, medan perang Tianxuan, medan perang kekacauan, penaklukan iblis hutan belantara, aula jiwa perang, dll., Dan berbagai elemen budidaya seperti hewan peliharaan, tunggangan, batu permata, peralatan, dll., Dan juga memperkenalkan gameplay sosial seperti obrolan waktu nyata, sistem hubungan cinta, dan foto kecantikan.',
        downText: 'Unduh Sekarang',
        img01: '/img/new_play08_on.png',
        img02: '/img/new_play09_on.png',
        img03: '/img/new_play10_on.png',
        img04: '/img/new_play11_on.png',
        img05: '/img/new_play12_on.png',




    },
}