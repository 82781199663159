import Vue from 'vue'
import VueRouter from 'vue-router'
// 导入组件库
import Home from '../views/Home.vue'
import Mobile from '../views/Mobile.vue'
import ImgInfo from '../views/ImgInfo.vue'
import Policy from '../views/Policy.vue'

const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function (location) {
  return originalReplace.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    component: Mobile
  },
  {
    path: '/mobile',
    component: Mobile
  },
  {
    path: '/imgInfo',
    name: "ImgInfo",
    component: ImgInfo
  },
  {
    path: '/policy',
    name: "Policy",
    component: Policy
  },
]

const router = new VueRouter({
  mode: 'hash',
  routes
})

export default router
