<template>
  <div>
    <!-- 定义路由来匹配要显示的组件 -->
    <router-view></router-view>
  </div>
</template>

<script>
import copy2clipboard from "@/utils/copy2clipboard";
import urlUtils from "@/utils/urlUtils";
import { viewContent } from "@/api/download";
// (function (w, d, s, q, i) {
//   w[q] = w[q] || [];
//   var f = d.getElementsByTagName(s)[0],
//     j = d.createElement(s);
//   j.async = true;
//   j.id = "beacon-aplus";
//   j.src = "https://d.alicdn.com/alilog/mlog/aplus/" + i + ".js";
//   f.parentNode.insertBefore(j, f);
// })(window, document, "script", "aplus_queue", "203467608");

// //集成应用的appKey
// aplus_queue.push({
//   action: "aplus.setMetaInfo",
//   arguments: ["appKey", "644a1b5fba6a5259c44472aa"],
// });

// aplus_queue.push({
//   action: "aplus.sendPV",
//   arguments: [{ is_auto: false }],
// });

// add this before event code to all pages where PII data postback is expected and appropriate
// ttq.identify({
//   email: "<hashed_email_address>", // string. The email of the customer if available. It must be hashed with SHA-256 on the client side.
//   phone_number: "<hashed_phone_number>", // string. The phone number of the customer if available. It must be hashed with SHA-256 on the client side.
//   external_id: "<hashed_extenal_id>", // string. Any unique identifier, such as loyalty membership IDs, user IDs, and external cookie IDs.It must be hashed with SHA-256 on the client side.
// });

// ttq.track("ViewContent", {
//   contents: [
//     {
//       content_id: "<content_identifier>", // string. ID of the product. Example: "1077218".
//       content_type: "<content_type>", // string. Either product or product_group.
//       content_name: "<content_name>", // string. The name of the page or product. Example: "shirt".
//     },
//   ],
//   value: "<content_value>", // number. Value of the order or items sold. Example: 100.
//   currency: "<content_currency>", // string. The 4217 currency code. Example: "USD".
// });

// ttq.track("ClickButton", {
//   contents: [
//     {
//       content_id: "<content_identifier>", // string. ID of the product. Example: "1077218".
//       content_type: "<content_type>", // string. Either product or product_group.
//       content_name: "<content_name>", // string. The name of the page or product. Example: "shirt".
//     },
//   ],
//   value: "<content_value>", // number. Value of the order or items sold. Example: 100.
//   currency: "<content_currency>", // string. The 4217 currency code. Example: "USD".
// });

// ttq.track("Download", {
//   contents: [
//     {
//       content_id: "<content_identifier>", // string. ID of the product. Example: "1077218".
//       content_type: "<content_type>", // string. Either product or product_group.
//       content_name: "<content_name>", // string. The name of the page or product. Example: "shirt".
//     },
//   ],
//   value: "<content_value>", // number. Value of the order or items sold. Example: 100.
//   currency: "<content_currency>", // string. The 4217 currency code. Example: "USD".
// });

export default {
  name: "App",

  data() {
    return {};
  },
  created() {
    //进入页面
    ttq.track("ViewContent", {
      contents: [
        {
          content_id: "4",
          content_type: "viewContent",
          content_name: "plug.cxcygame.com",
        },
      ],
    });
    //版本1接入快手
    // kwaiq.track("contentView");
  },
  methods: {},
};
</script>

<style lang="less" scoped>
::-webkit-scrollbar {
  /*隐藏滚轮*/
  display: none;
}
</style>>
